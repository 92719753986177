import React from 'react'

export const Table = ({ children }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full text-left dark:text-white ">
        {children}
      </table>
    </div>
  );
};

export const Th = ({ children }) => {
  return (
    <th className="px-4 py-2">
      {children}
    </th>
  );
};

export const Td = ({ children }) => {
  return (
    <td className="px-4 py-2">{children}</td>
  );
};

export const Tr = ({ children }) => {
  return <tr className="hover:bg-neutral-700/30">{children}</tr>;
};
