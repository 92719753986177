import { createModel } from '@rematch/core';
import http from '../utils/http';
import { replaceMessagesFilesPaths } from '../utils/text';
import { THREAD_ACTION_TYPES } from '../constants/thread.const';
import { delay } from '../utils/delay';
import { EXTENSION_TO_MIME_TYPES } from '../constants/files.const';

const ThreadStore = createModel()({
  name: 'thread',
  state: {
    thread: {},
    historyList: [],
    threadId: 0,
    loading: false,
    showWelcomeMessage: true,
    lastMessage: '',
    lastStream: '',
    showTypingEffect: false,
    location: '',
    showStreaming: false,
    chartData: null,
    hitsResults: null,
    action: THREAD_ACTION_TYPES.CHAT,
    chatModal: 'LLAMA',
    progressPercentage: 0,
    downloadLoading: false,
  },
  reducers: {
    hideWelcomeMessageReducer(state, payload) {
      return {
        ...state,
        showWelcomeMessage: payload
      };
    },
    emptyThreadReducer(state, payload) {
      return {
        ...state,
        thread: {},
        threadId: 0,
        lastMessage: ''
      };
    },
    loadingReducer(state, payload) {
      return {
        ...state,
        loading: payload
      };
    },
    sendPromptReducer(state, payload) {
      return {
        ...state,
        thread: payload
      };
    },
    getHistoryReducer(state, payload) {
      return {
        ...state,
        historyList: payload
      };
    },
    setThreadIdReducer(state, payload) {
      return {
        ...state,
        threadId: payload
      };
    },
    setLastMessageReducer(state, payload) {
      return {
        ...state,
        lastMessage: payload
      };
    },
    setLastStreamReducer(state, payload) {
      return {
        ...state,
        lastStream: payload
      };
    },
    showTypingEffectReducer(state, payload) {
      return {
        ...state,
        showTypingEffect: payload
      };
    },
    showStreamingReducer(state, payload) {
      return {
        ...state,
        showStreaming: payload
      };
    },
    changeLocationReducer(state, payload) {
      return {
        ...state,
        location: payload
      };
    },
    startNewThreadReducer(state, payload) {
      return {
        ...state,
        threadId: payload,
        thread: {}
      };
    },
    setChartData(state, payload) {
      return {
        ...state,
        chartData: payload
      };
    },
    clearChartData(state, payload) {
      return {
        ...state,
        chartData: null
      };
    },
    setHitsResults(state, payload) {
      return {
        ...state,
        hitsResults: payload
      };
    },
    clearHitsResults(state, payload) {
      return {
        ...state,
        hitsResults: null
      };
    },
    setAction(state, payload) {
      return {
        ...state,
        action: payload
      };
    },
    setChatModal(state, payload) {
      return {
        ...state,
        chatModal: payload
      };
    },
    setProgress(state, payload) {
      return {
        ...state,
        progressPercentage: payload
      };
    },
    clearProgress(state, payload) {
      return {
        ...state,
        progressPercentage: 0
      };
    },
    setDownloadLoading(state, payload) {
      return {
        ...state,
        downloadLoading: payload
      };
    }
  },
  effects: (dispatch) => ({
    async emptyThread(payload, state) {
      dispatch.thread.emptyThreadReducer();
    },
    async setLastStream(payload, state) {
      dispatch.thread.setLastStreamReducer(payload);
    },
    async changeLocation(payload, state) {
      dispatch.thread.changeLocationReducer(payload);
    },
    async startNewThread(payload, state) {
      const { users, thread } = state;
      if (users.token) {
        const res = await http.post(
          `/thread?assistantType=${payload.assistantType}${payload?.service ? `&service=${payload?.service}` : ''}`,
          {
            provider: thread.chatModal,
            lngOwnerId: users.data.id
          },
          {
            headers: { 'content-type': 'multipart/form-data' }
          }
        );

        dispatch.thread.startNewThreadReducer(res.data.id);
      }
    },
    async sendPrompt(payload, state) {
      console.log('this is the payload', payload);
      dispatch.thread.showStreamingReducer(true);
      dispatch.thread.loadingReducer(true);
      dispatch.thread.setLastMessageReducer(payload.text);
      dispatch.thread.hideWelcomeMessageReducer(false);
      const formData = new FormData();

      if (payload.files.length > 0) {
        for (const file of payload.files) {
          formData.append('files', file);
        }
      }

      if (payload.text) {
        formData.append('message', payload.text);
      }
      if (state.thread.location) {
        formData.append('assistantType ', state.thread.location);
      }

      let res = {};

      try {
        res = await http.post(
          `/thread/${payload.threadId}/message?runAssistant=false${payload?.service ? `&service=${payload?.service}` : ''}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
              const percentage = Math.floor(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              dispatch.thread.setProgress(percentage);
            }
          }
        );

        dispatch.thread.clearProgress();
        dispatch.thread.sendPromptReducer(res.data);
        dispatch.thread.showTypingEffectReducer(true);
        dispatch.thread.loadingReducer(false);
      } catch {
        dispatch.thread.loadingReducer(false);
      }
    },
    async sendFalakPrompt(payload, state) {
      const { users } = state;
      const formData = new FormData();
      dispatch.thread.setHitsResults(null);

      dispatch.thread.showStreamingReducer(true);
      dispatch.thread.loadingReducer(true);
      dispatch.thread.setLastMessageReducer(payload.prompt);
      dispatch.thread.hideWelcomeMessageReducer(false);

      try {
        if (payload?.files?.length > 0) {
          for (const file of payload.files) {
            formData.append('file', file);
          }
          await http.post(
            `/falak/${users.data.id}/upload-data`,
            formData
          );
          await delay(500);
        }

        // TODO (to be changed) - This is a temporary solution to get the search results
        if (payload.prompt) {
          const searchRes = await http.get(
            `/falak/${users.data.id}/search?query=${payload.prompt}&page=${payload.page}&limit=${payload.limit}`
          );
          dispatch.thread.setHitsResults(searchRes.data);
        }

        dispatch.thread.showTypingEffectReducer(true);
        dispatch.thread.loadingReducer(false);
      } catch (error) {
        dispatch.thread.loadingReducer(false);
      }
    },
    async getHistory(payload, state) {
      const { users } = state;
      const history = await http.get(
        `thread?lngOwnerId=${users.data.id}&assistantType=${payload}`
      );
      dispatch.thread.getHistoryReducer(history.data);
    },
    async getThread(payload, state) {
      dispatch.thread.showStreamingReducer(false);
      dispatch.thread.setThreadIdReducer(payload.id);
      dispatch.thread.showTypingEffectReducer(false);

      const thread = await http.get(
        `thread/${payload.id}/message?assistantType=${payload.assistantType}`
      );
      replaceMessagesFilesPaths(thread.data.messages);

      if (payload?.userMessage) {
        thread.data.messages = [...thread.data.messages, payload.userMessage];
      }

      const prevThreadMessages = state?.thread?.thread?.messages;
      if (prevThreadMessages?.length < thread.data.messages?.length && payload.userMessage) {
        prevThreadMessages.forEach((message, i) => {
          if (message.files) {
            thread.data.messages[i].files = message.files
          }
        });
      }

      dispatch.thread.sendPromptReducer(thread.data);
      dispatch.drawer.openDrawer(false);
    },
    async downloadLastMessage(payload, state) {
      dispatch.thread.setDownloadLoading(true);
      try {
        const response = await http.post(
          `thread/${payload.threadId}/messages/export`,
          {
            type: payload.type
          },
          { responseType: 'arraybuffer' }
        );
        const blob = new Blob([response.data], { type: EXTENSION_TO_MIME_TYPES[payload.type] });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = response.headers['content-disposition']?.replace('attachment; filename=', '') ?? `file.${payload.type}`;
        document.body.appendChild(link);
        link.click();
        dispatch.thread.setDownloadLoading(false);

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
        dispatch.thread.setDownloadLoading(false);
      }
    }
  })
});

export default ThreadStore;
