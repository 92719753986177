import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { processCombinedText } from '../../utils/text';
import Code from './code';
import { Table, Td, Th, Tr } from './table';
import { useEffect, useState } from 'react';

const Markdown = ({ children }) => {
  const [processedText, setProcessedText] = useState([]);

  useEffect(() => {
    setProcessedText(processCombinedText(children));
  }, [children]);

  return (
    <div className='w-11/12'>
      {processedText.map(({ direction, text }, index) => {
        return (
          <div key={index} className='mb-2 mt-2 leading-7' style={{ direction }}>
            <ReactMarkdown
              className='prose markdown'
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                code: Code,
                table: Table,
                th: Th,
                td: Td,
                tr: Tr,
              }}
            >
              {text}
            </ReactMarkdown>

          </div>
        )
      })}
    </div>
  );
};

export const mkTypes = {
  image_file: (fileId) =>
    `\n ![${fileId}](https://stage.7saip.com/api/assistant/getFile/${fileId})`,
  file_path: (fileId) =>
    `https://stage.7saip.com/api/assistant/getFile/${fileId}`
};

export default Markdown;