import { useState } from 'react'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useTranslation } from 'react-i18next';

const Code = ({ node, inline, className, children, ...props }) => {
  const match = /language-(\w+)/.exec(className || "");
  const codeText = String(children).replace(/\n$/, "");
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = (codeText) => {
    navigator.clipboard.writeText(codeText).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    });
  }

  return !inline && match ? (
    <div className="relative my-4 overflow-hidden rounded-xl border border-gray-700 shadow-lg">
      <div className="flex items-center justify-between dark:bg-gray-800 bg-gray-200 px-4 py-2 text-sm font-semibold dark:text-gray-300 text-gray-400">
        <span className="uppercase">{match[1]}</span>
        <button
          className="flex items-center gap-1 px-2 py-1 text-xs font-medium rounded-md dark:hover:bg-gray-700 hover:bg-gray-300 capitalize"
          onClick={() => handleCopy(codeText)}
        >
          {copied ? (
            <>
              <i className="pi pi-check text-green-400" /> {t('shared.copied')}
            </>
          ) : (
            <>
              <i className="pi pi-copy text-gray-400" /> {t('shared.copy')}
            </>
          )}
        </button>
      </div>

      <SyntaxHighlighter
        style={materialDark}
        language={match[1]}
        PreTag="div"
        className="!m-0 !p-4 dark:!bg-neutral-900 !bg-neutral-100"
        {...props}
      >
        {codeText}
      </SyntaxHighlighter>
    </div>
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
}

export default Code;